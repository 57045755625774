<template>
  <div>
    <label class="form-check-label" :class="additionalClass" :for="label">
      {{ label }}
    </label>
    <input
      :value="modelValue"
      class="form-control"
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      :name="label"
      @input="emitInput"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    disabled: {
      type: Boolean,
    },

    type: {
      type: String,
      default: 'text',
    },
    additionalClass: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    emitInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>

<style lang="scss">
input::placeholder {
  color: gray;

  font-weight: 300;
  font-size: 15px;
}
</style>
